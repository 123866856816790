import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { catchError, map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { environment } from '@core/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeneralFileUploadService {
  http = inject(HttpClient);
  authService = inject(AuthService);
  private loadingService = inject(LoadingService);
  handler = inject(HttpBackend);

  BASE_URL = `${environment.ADMIN_BASE_URL}/v1/kyc/upload-large-kyc-file`;

  constructor() {}

  async getPresignedUrl(account_id: string): Promise<string> {
    try {
      const response = await firstValueFrom(
        this.http
          .get<{
            status: string;
            message: string;
            data: { presigned_url: string };
          }>(`${this.BASE_URL}?account_id=${account_id}`)
          .pipe(
            map((response) => {
              if (!response || !response.data || !response.data.presigned_url) {
                throw new Error('Invalid response from server');
              }
              return response.data.presigned_url;
            }),
            catchError((error) => {
              console.error('Error fetching presigned URL', error);
              throw error;
            })
          )
      );
      if (!response) {
        throw new Error('Presigned URL is undefined');
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  uploadFile(url: string, formData: any): Promise<any> {
    this.http = new HttpClient(this.handler);

    return firstValueFrom(
      this.http
        .put(url, formData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/pdf',
          }),
        })
        .pipe(
          catchError((error) => {
            throw error;
          })
        )
    );
  }
}
